.ul {
  display: flex;
  flex-direction: row;
  padding: 0;
  gap: 5rem;
  li {
    list-style: none;
    a {
      text-decoration: none;
    }
  }
}
